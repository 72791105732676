import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Navegacion from './nav';
import { AiFillCode } from 'react-icons/ai';
import { IconContext } from "react-icons";

const EnlaceHome = styled(Link)`
    color: #FFF;
    text-align: center;
    text-decoration: none;
    margin: 0px;
`;

const HeaderDiv = styled.header`
    position:absolute;
    z-index: 9999;
    width: 90%;
    padding: 1rem;
    margin: 0px;
    height: 70px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 100% !important;
    @media (max-width: 768px) {
                height: 106px;
    }
`;

const Header = () => {

    return (
        <HeaderDiv
            id="header-whole"
        >
            <div
                css={css`
                    max-width: 1200px;
                    margin: 0 auto;
                     
                    @media (min-width: 768px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                `}
            >
                <EnlaceHome
                    to='/'
                ><h1
                    css={css`
                        margin: 0px;
                    `}
                >
                c
                    <IconContext.Provider value={{ "margin-top": "10px" }}>
                                <AiFillCode size={25} />
                    </IconContext.Provider>
                    b
                </h1>
                </EnlaceHome>


                <Navegacion />
            </div>
        </HeaderDiv>
    );
}

export default Header;