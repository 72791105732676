import React from 'react';
import Header from './header';
import { Global, css } from '@emotion/core';
import Helmet from 'react-helmet'
import Footer from './footer';
import { withPrefix } from "gatsby"
import {graphql, useStaticQuery } from 'gatsby'



const Layout = (props) => {

    const {image} = useStaticQuery(graphql`
       {
        image: file(relativePath: {eq: "paven.png"}) {
            sharp: childImageSharp {
            fixed(width: 18) {
                src
            }
            }
        }
        }
    `);

    console.log(image.sharp.fixed.src);
    

    return (
        <>
            <Global
                styles={css`
                    html {
                        font-size: 65.5%;
                        box-sizing: border-box;
                    }
                    *, *:before, *:after {
                        box-sizing: inherit;
                    }
                    body {
                        font-size: 16px;
                        font-size: 1.8rem;
                        line-height: 1.5;
                        font-family: 'PT Sans', sans-serif; 
                        background-image: ${image.sharp.fixed.src};
                        margin: 0;
                        padding: 0;
                    }
                    h1, h2, h3 {
                        margin: 0;
                        line-height: 1.5;
                    }
                    h1, h2 {
                        font-family: 'Roboto', serif;
                    }
                    h1{
                        font-family: 'PT Sans', sans-serif;

                    }
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                    }

                    .navbar-color {
                        background-color: rgb(44,62,80) !important;
                        width: 100% !important;
                    }

                    .clearfix {
                        float: none;
                        clear: both;
                    }
                `}

            />
            <Helmet>
                <title>WebSite Camilo Buitrago</title>
                <html lang="en" />
                <meta name="description" content="Web Page Camilo Buitrago WebSite software developer react programer" />
                {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
                    integrity="sha256-l85OmPOjvil/SOvVt3HnSSjzF1TUMyT9eV0c2BzEGzU=" crossOrigin="anonymous" /> */}
                <link href="https://fonts.googleapis.com/css?family=PT+Sans:400,700|Roboto+Slab:400,700&display=swap" rel="stylesheet" />               
                <script src={withPrefix('generalFunctions.js')} type="text/javascript" />

            </Helmet>
            <Header />
            {props.children}
            <Footer
                title="WebSite Camilo Buitrago"
            />

        </>
    );
}

export default Layout;