import React from 'react';
// import { Link } from 'gatsby';
import styled from '@emotion/styled';

const Nav = styled.nav`
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
    @media (min-width: 768px){
        padding-bottom: 0;
    }
`;

// const NavLink = styled(Link)`
const NavLink = styled.a`
    display: block;
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1rem;
    font-family: 'PT Sans', sans-sefir;
    text-decoration: none;
    padding: 1rem;
    margin-right: 1rem;

    &:last-of-type {
         margin-right: 0;
    }
    &.pagina-actual {
        border-bottom: 2px solid #fff;
    }

    &:hover{
        color: #37BCF9;
        transform: scale(1.2,1.2);
    }

    @media (max-width: 428px){
        font-size: 1.2rem;
    }
`;

const Navegacion = () => {
    return ( 
        <Nav>
            <NavLink 
                href="#first"
                >Home</NavLink>
            
            <NavLink 
                href="#about"
                >About Me</NavLink>
            <NavLink 
                href="#projects"
                >Projects</NavLink>
            <NavLink 
                href="#contact"
                >Contact me</NavLink>
        </Nav>
     );
}
 
export default Navegacion;